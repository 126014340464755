import * as React from 'react';
import Base from '@webLayouts/Base/Base';
import Container from '@webAtoms/Container/Container';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Text, { FontStyle } from '@webAtoms/Text/Text';

const NotFoundPage: React.FC = () => (
  <Base theme="light">
    <Container>
      <Box padding={Spacing.S}>
        <Box paddingTop={Spacing.Xl3} paddingBottom={Spacing.Xl3}>
          <Text semantic="h1" fontStyle={FontStyle.Hero}>
            Page not found
          </Text>
          <Box paddingTop={Spacing.L}>
            <Text semantic="p" fontStyle={FontStyle.BodyLarge}>
              Sorry we couldn&apos;t find the page you were looking for.
            </Text>
          </Box>
        </Box>
      </Box>
    </Container>
  </Base>
);

export default NotFoundPage;
